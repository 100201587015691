import i18n from '../../i18n';
import commentsHelper from '../../helpers/comments/comments.helper';
import { formTypes } from '../../enums/creator.enums';
import { caseTypes } from '../../enums/cases.enums';
import { tiptapStates } from '../../enums/comments.enums';
import { format } from 'date-fns';

// State object
const state = {
    showAttachCheckBoxes: false,
};

const actions = {
    setShowAttachCheckBoxes({ commit }, payload) {
        commit('SET_SHOW_ATTACH_CHECKBOXES', payload);
    },

    toggleShowAttachCheckBoxes({ commit, state, dispatch }) {
        if (!state.showAttachCheckBoxes) {
            dispatch('Comments/checkAllComments', null, { root: true });
        }
        commit('SET_SHOW_ATTACH_CHECKBOXES', !state.showAttachCheckBoxes);
    },

    executeAnswerButton({ rootState, rootGetters, dispatch }, payload) {
        const comment = payload ? { ...payload } : rootGetters['Comments/getLatestEmailComment'];

        if (rootState.Comments.tiptapState !== tiptapStates.OPEN) {
            dispatch('Comments/openTiptap', null, { root: true });
        }
        const newFrom = commentsHelper.getSenderFromEmail(comment);
        // 2. Set the current comment we click on to the create comments answer
        const newRecipients = commentsHelper.getRecipientsFromEmail({
            email: comment,
            systemEmails: rootState.Cases.systemEmails,
        });

        dispatch('Comments/setEmailFrom', newFrom, { root: true });
        dispatch('Comments/setEmailToRecipients', newRecipients, { root: true });
        dispatch('Comments/setEmailCcRecipients', [], { root: true });
        dispatch('Comments/setEmailBccRecipients', [], { root: true });
        dispatch('Comments/setSubject', comment.subject, { root: true });

        // When we click on the answer button, we want to close cc and bcc
        dispatch('Comments/setShowCCs', false, { root: true });

        // When we click on the answer button, we change the current state to external
        const external = true;
        dispatch('Comments/setCurrentExternalState', external, { root: true });
        dispatch('Comments/setRepliedComment', comment.id, { root: true });
    },
    // This function needs to add ccTo and bccTo
    executeAnswerAllButton({ rootState, dispatch }, payload) {
        // 1. If create comments isnt open, open it
        if (rootState.Comments.tiptapState === tiptapStates.CLOSED) {
            dispatch('Comments/openTiptap', null, { root: true });
        }

        // 2. Set the current comment we click on to the create comments answer all
        const { ccTo, bccTo, subject, id } = payload;
        const newFrom = commentsHelper.getSenderFromEmail(payload);
        const newRecipients = commentsHelper.getRecipientsFromEmail({
            email: payload,
            answerAll: true,
            systemEmails: rootState.Cases.systemEmails,
        });
        dispatch('Comments/setEmailToRecipients', newRecipients, { root: true });
        dispatch('Comments/setEmailFrom', newFrom, { root: true });

        // If we have cc or bcc, we want to show the ccs, otherwise we want to hide them
        // eslint-disable-next-line no-unneeded-ternary
        dispatch('Comments/setShowCCs', ccTo || bccTo ? true : false, { root: true });

        dispatch('Comments/setEmailCcRecipients', ccTo || [], { root: true });
        dispatch('Comments/setEmailBccRecipients', bccTo || [], { root: true });

        dispatch('Comments/setSubject', subject, { root: true });

        // When we click on the answer all button, we change the current state to external
        const external = true;
        dispatch('Comments/setCurrentExternalState', external, { root: true });
        dispatch('Comments/setRepliedComment', id, { root: true });
    },

    async executeForwardButton({ rootState, commit, dispatch }, { caseId, commentId = 0 }) {
        try {
            // 1. Get all comments in the current case
            console.log('Executing executeForwardButton with caseiD:', caseId, 'and commentId:', commentId);
            const payload = {
                caseId: rootState.Cases.selectedCase.case.caseId,
                load: false,
                commentId: null,
                search: null,
                itemsPerPage: 100,
            };

            const caseData = await dispatch('Cases/getCaseContent', payload, { root: true });

            const filteredComments = caseData.comments.filter((comment) => {
                // * if commentId is 0, we want to get all comments
                if (commentId === 0) {
                    return comment.typeOfMessage === caseTypes.EMAIL;
                }
                return comment.typeOfMessage === caseTypes.EMAIL && comment.id <= commentId;
            });

            // take the current content form the tip tap and add it to comment content
            let commentContent = commentId ? '' : rootState.Comments.content;

            if (filteredComments.length > 0) {
                const commentStructure = [
                    `<br><hr>`,
                    `<br>`,
                    `<p><strong>{fromTitle}:</strong> {from}</p>`,
                    `<p><strong>{sendTitle}:</strong> {dateTime}</p>`,
                    `<p><strong>{toTitle}:</strong> {to}</p>`,
                    `<p><strong>{subjectTitle}:</strong> {subject}</p>`,
                    `<br>`,
                    `<p style="padding: 10px 0px">{comment}</p>`,
                ];

                for (const comment of filteredComments) {
                    let isFirstComment = true;

                    for (const template of commentStructure) {
                        const replacedTemplate = template
                            .replace('{from}', comment.from)
                            .replace('{dateTime}', format(new Date(comment.dateTime), 'yyyy-MM-dd HH:mm:ss'))
                            .replace('{to}', commentsHelper.convertToString(comment.to, comment.ccTo, comment.bccTo))
                            .replace('{subject}', comment.subject)
                            .replace('{comment}', comment.comment.replaceAll('\n', '<br>'))
                            .replace('{fromTitle}', i18n.t('comment.from'))
                            .replace('{sendTitle}', i18n.t('comment.send'))
                            .replace('{toTitle}', i18n.t('comment.to'))
                            .replace('{subjectTitle}', i18n.t('comment.subject'));

                        if (!isFirstComment && template === '<br><hr>') {
                            commentContent += '<hr>';
                        } else {
                            isFirstComment = false;
                        }

                        commentContent += replacedTemplate;
                    }
                }
            }

            // 1. We need to grab all inline attachments and add them to the inline data we are about to send
            const div = document.createElement('div');
            div.innerHTML = commentContent;
            commentContent = div.innerHTML;

            commit('Comments/SET_TIPTAP_STATE', tiptapStates.OPEN, { root: true });
            await this._vm.$nextTick();

            await dispatch(
                'Comments/setCommentContent',
                { content: commentContent, caseId, isForwarding: true, external: true },
                { root: true },
            );
            commit('Comments/SET_TIPTAP_STATE', tiptapStates.FULL, { root: true });

            // Clear and hide CC and BCC fields
            dispatch('Comments/setEmailToRecipients', [], { root: true });
            dispatch('Comments/setEmailCcRecipients', [], { root: true });
            dispatch('Comments/setEmailBccRecipients', [], { root: true });
            dispatch('Comments/setShowCCs', false, { root: true });

            dispatch('Comments/setRepliedComment', null, { root: true });

            const files = filteredComments.flatMap((comment) => comment.attachments);
            await dispatch('Files/setFilesToCase', { files, caseId }, { root: true });
        } catch (error) {
            // Toast could not forward the case
            this._vm.$toasted.show('Could not load the case', {
                icon: 'mdi-alert-circle',
                type: 'error',
            });

            console.log(error);
        }
    },
    executeAnswerSmsButton({ dispatch }, payload) {
        // When we click on the answer sms button, we want to open the send sms form
        const { from, caseId, outgoing, receiverNumber } = payload;

        // Who to send the sms to
        // * If the sms is outgoing, we want to send it to the reciever
        // * If the sms is incoming, we want to send it to the from number
        const number = outgoing ? receiverNumber : from;
        const sender = outgoing ? from : receiverNumber;

        dispatch(
            'Creator/openForm',
            {
                id: formTypes.CREATE_SMS_TO_CASE,
                keys: {
                    respondent: {
                        value: number,
                    },
                    caseId: {
                        value: caseId,
                    },
                    sender: {
                        value: sender,
                    },
                },
            },
            { root: true },
        );
    },
};
// Mutations
const mutations = {
    SET_SHOW_ATTACH_CHECKBOXES(state, payload) {
        state.showAttachCheckBoxes = payload;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
