import cases from '@/api/cases/cases';
import router from '@/router';
import helper from '@/helpers/cases/cases.helper';
import client from '@/api/client/client';
import { httpStatusCode } from '@/enums/response.enums';

import { mapFilterPayload } from '@/utils/Cases';
import { findSignature } from '@/helpers/signature.helpers';

import {
    mailRegEx,
    extractValidValuesFromArray,
    extractValidValuesFromObject,
    filterObjectsByKeys,
    getActiveFromSettings,
} from '@/utils';
import * as Sentry from '@sentry/vue';

import { casesHeaders, channelTypes, statusTypes } from '../../templates/cases.template';
import {
    states,
    caseKeys,
    callbackTypes,
    caseStatuses,
    caseTypes,
    categories as categoryEnums,
    usersSpecialCases,
} from '../../enums/cases.enums';

import i18n from '../../i18n';

import commentsHelper from '../../helpers/comments/comments.helper';
import { hydrateImages } from '../../directives/shadowDom';

// State object
const state = {
    caseTravelState: !localStorage.getItem('caseTravelState'),
    caseTravelMinimized: false,
    animate: false,
    currentCaseSubscription: [],
    searchQuery: '',
    showFiltersState: localStorage.getItem('showFiltersState') || 'Minimera filtermeny',
    showFilters: localStorage.getItem('filtersState') || 0,
    searchType: JSON.parse(localStorage.getItem('searchType')) || 1,
    errorCount: 0,
    moveCase: 0,
    listAddAnimation: false,
    dateNow: new Date(),
    selectedCases: [],
    showSelect: '',
    selectedUserAssign: { value: 0 },
    selectedStatusAssign: {
        color: '#cf73e0',
        icon: 'mdi-tray',
        name: 'Öppna',
        nameTranslate: {
            sv: 'Öppna',
            en: 'Open',
        },
        value: caseStatuses.OPEN,
    },
    selectedCategoryAssign: [],
    selectedQueueAssign: {
        value: 0,
        color: 'primary',
        textKey: 'casesStore.noQueue',
        icon: 'mdi-account-multiple',
    },
    listLayout: JSON.parse(localStorage.getItem('__listLayout')) || {
        name: 'Stor (80 px)',
        nameTranslate: {
            sv: 'Stor (80 px)',
            en: 'Big (80 px)',
        },
        value: 'big',
        icon: 'mdi-plus',
    },
    cases: [],
    busyCaseList: [],
    totalCases: -1,
    selectedCase: null,
    selectedCaseId: null,
    selectedCaseBusy: false,

    caseFilters: {
        statuses: statusTypes,
        categories: [],
        channels: [],
        users: [
            {
                value: usersSpecialCases.ALLUSERS, // Default alla tidigare, vad är det för default nu?
            },
        ],
        // users: {
        //     UserID: 0,
        //     Name: 'Alla',
        //     nameTranslate: {
        //         sv: 'Alla',
        //         en: 'All',
        //     },
        //     value: 0,
        // },
        showUnansweredOnly: false,
        queues: [],
        brands: [],
    },
    changeQueues: [],
    caseFiltersState: {
        statuses: [],
        categories: [],
        channels: [],
        users: [], // WHY123? Borde vara alla. Är det här alla? Finns det hantering i backend?
        queues: [],
        brands: [],
        searchQuery: '',
        page: 1,
        items: 5,
        showUnansweredOnly: false,
        sortField: 'internalType',
        sortDirection: 'ASC',
    },
    // new caes stores values end
    activeUserCases: [],
    casesList: [],
    reloadCaseContent: false,
    closeChat: false,
    casesInQueue: [],
    sideBarView: 'default',
    temporaryTemplate: '',
    clientNowOnCard: null,
    clientContactInfo: null,
    callbackCallNow: false,
    listSelect: false,
    toggleCommunication: false,
    countdownInterval: null,
    CommunicationCreatorData: {
        type: 0,
    },
    normalAlertTrigger: {},
    systemEmails: [],
    systemSmsNumbers: [],
    commentView: 'casecomments',
    answeredCase: false,
    caseData: {},
    goToContent: false,
    loading: false,
    loadingTimeout: null,
    showAttachCheckboxes: false,
    allCommentsInCaseChecked: true,
    showHeaders: false,
    caseTravel: [],
    inQueueTimer: null,
    removedCaseIndex: null,
    headerLock: true,
    selectedCaseSignature: {},
    casesListHeaders: [],
    listHeaders: {
        headers: [],
        currentBreakpoint: 0,
    },

    // loaders
    caseState: states.INIT,
    clientState: states.INIT,
    historyState: states.INIT,
    travelState: states.INIT,
    filtersLoaded: false,

    //  comments
    currentPage: 1,
    caseToLoad: null,
    categories: [],
    caseTimeline: [],
    selectedIframe: null,
    selectedOrderIntegration: null,

    // attachment adder
    attachmentToAdd: [],

    // animation grid
    animationGrid: [],
    animationGridQueue: [],

    hasMoreComments: false,

    timelineItems: [],
};
// Getter functions
const getters = {
    getCategoriesWithoutUncategorized(state) {
        return state.caseFilters.categories.filter((category) => category.id !== categoryEnums.NO_CATEGORY);
    },
    getCategoriesList(state) {
        return state.categories;
    },
    isBasic(state) {
        return state.selectedCase?.case?.internalType === caseTypes.SOCIAL;
    },
    getCaseUserPersonNr(state) {
        if (state.clientNowOnCard) {
            return state.clientNowOnCard.data.socialSecurityNumber;
        }
        return '';
    },
    getSelectedCase(state) {
        return state.selectedCase;
    },
    getSystemEmails(state) {
        return state.systemEmails;
    },
    getCaseData(state) {
        return state.caseData;
    },
    getGoToContent(state) {
        return state.goToContent;
    },
    selectedCaseActiveUserId(state) {
        if (!state.busyCaseList.length) return null;
        const activeUserItem = state.busyCaseList.find(
            (item) => item.id === state.selectedCase?.case?.caseId && item.activeUser,
        );
        return activeUserItem ? activeUserItem.userId : null;
    },

    isReadOnly(state, getters, rootState) {
        // If we are loading a case dont show overlay
        if (state.selectedCase?.case?.caseId !== state.caseToLoad) {
            return false;
        }

        // If a case is in queue, it should be read only
        if (state.selectedCase?.case?.status === caseStatuses.IN_QUEUE) {
            return true;
        }

        // If we are loading the case dont show the overlay
        if (state.selectedCase.case.caseId !== state.caseToLoad) {
            return false;
        }

        const activeUser =
            getters.selectedCaseActiveUserId === rootState.Auth.userObject.userId || !getters.selectedCaseActiveUserId;
        return !activeUser;
    },
    getContactPerson(state) {
        if (!state.clientNowOnCard?.contactPersons?.length) return null;
        return state.clientNowOnCard?.contactPersons?.find((person) => {
            return (
                person.Email === state.clientNowOnCard.caseFrom.email ||
                person.MobileNumber === state.clientNowOnCard.caseFrom.email
            );
        });
    },

    // eslint-disable-next-line unicorn/consistent-function-scoping
    isAllowedToAssignCase: () => (item) => {
        const notAllowedTypes = [caseTypes.CALL, caseTypes.CALLBACK, caseTypes.CHAT];
        const isInQueue = item.status === caseStatuses.IN_QUEUE;
        const isNotAllowedType = notAllowedTypes.includes(item.internalType);
        const isAutomaticCallback = item.internalSubType === callbackTypes.CALLBACK_AUTO;
        if (isInQueue && isNotAllowedType) {
            if (isAutomaticCallback) {
                return 'automaticCallback'; // not allowed
            }
            if (item.internalType === caseTypes.CALL) {
                return 'callInQueue'; // not allowed
            }
            if (item.internalType === caseTypes.CHAT) {
                return 'chatInQueue'; // not allowed
            }
        }
        // In all other cases, the case is allowed to be assigned
        return true;
    },
    usersInCurrentCase(state) {
        const usersInThisCase = state.busyCaseList.filter((item) => item.id === state.selectedCase?.case?.caseId);

        let activeUser = usersInThisCase.find((item) => item.activeUser);

        if (activeUser) {
            activeUser = {
                userId: activeUser.userId,
                userName: activeUser.userName,
                isAssignedToCase: activeUser.isAssignedToCase,
            };
        }
        // make sure the otherUsers array does not contain duplicates of users (when using multiple tabs)
        const uniqueUserIds = new Set();
        const otherUsers = usersInThisCase
            .filter((item) => !item.activeUser && item.userId !== activeUser?.userId && !uniqueUserIds.has(item.userId))
            .map((item) => {
                uniqueUserIds.add(item.userId);
                return { userId: item.userId, userName: item.userName, isAssignedToCase: item.isAssignedToCase };
            });

        return {
            activeUser,
            otherUsers,
        };
    },
    caseIdRelations(state) {
        const relations = state.selectedCase?.case?.caseIdRelations;
        if (!relations || !Array.isArray(relations)) {
            return [];
        }

        return relations;
    },
    getSelectedCaseId(state) {
        if (state.selectedCase?.case?.caseId) {
            return state.selectedCase.case.caseId;
        }
        return null;
    },
    getSelectedCaseAttachments(state) {
        if (state.selectedCase?.attachments) {
            return state.selectedCase.attachments;
        }
        return null;
    },
};
// Actions
const actions = {
    SOCKET_surveyConfirmationNotification({ state, dispatch }, payload) {
        if (state.selectedCase?.caseStart?.CaseID === payload.case) {
            return;
        }

        this._vm.$toasted.show(i18n.t('casesStore.surveyConfirmation'), {
            icon: 'mdi-alert-circle',
            type: 'success',
            theme: 'toasted-information',
            duration: 60_000,
            action: {
                text: i18n.t('casesStore.surveyConfirmationGoToCase'),
                onClick: (_, toast) => {
                    dispatch('setGoToContent', true);
                    router.push({
                        name: 'Case',
                        params: {
                            caseId: payload.case,
                        },
                    });
                    toast.goAway(0);
                },
            },
        });
    },
    SOCKET_updatedCategories({ commit, dispatch }, payload) {
        commit('SET_CASEFILTER_CATEGORIES', payload);
        commit('SET_CASE_CATEGORIES', payload);
        dispatch('getCases', state.caseFiltersState);
    },
    SOCKET_wholeList({ commit, state, rootState }, payload) {
        try {
            const { userId: myUserId } = rootState.Auth.userObject;
            helper.showActiveUserChangedToasts(state.busyCaseList, payload, myUserId, this._vm.$toasted.show);
            commit('SET_BUSY_CASE_LIST', payload);
            commit('CLEAN_PRESENCE_IN_CASE_LIST');
            commit('SET_BUSY_CASES', { payload, myUserId });
        } catch (error) {
            console.error('Could not update whole list', error);
            Sentry.captureException(error);
        }
    },

    SOCKET_changeInCases({ dispatch, state, commit }, socketPayload) {
        const payload = socketPayload.data;
        const hasSearch = state.caseFiltersState.searchQuery.length;

        if (hasSearch) {
            return;
        }

        // Refactor this

        try {
            const { data, totalCases, cases } = payload;

            // remove this should also be in the backend
            if (state.busyCaseList.some((el) => el.id === data.CaseID)) {
                data.Busy = 'true';
            }

            // * timeline is either object, array or null
            if (state.caseTimeline) {
                try {
                    const timelineCases = structuredClone(state.caseTimeline?.cases) || [];
                    const affectedItem = timelineCases.find((item) => item.data.caseId === data.CaseID);

                    if (affectedItem) {
                        affectedItem.data.status = data.Status;
                        affectedItem.data.userId = data.UserId;
                        affectedItem.data.userName = data.UserName;

                        commit('SET_CASE_TIMELINE', { ...state.caseTimeline, cases: timelineCases });
                    }
                } catch (error) {
                    console.log('Timeline issue', error);
                }
            }

            dispatch('addItemsToList', { totalCases, cases });
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.noUpdateList') + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
        }
    },

    async SOCKET_changeInActiveCases({ dispatch }, newVal) {
        const { caseThatChanged, action } = newVal;

        if (action === 'remove') {
            dispatch('closeCaseTab', caseThatChanged.CaseID);
        } else if (action === 'addOrUpdate') {
            const { icon } = channelTypes.find((t) => t.value === caseThatChanged.InternalType);

            const { CaseID, ClientID, InternalType, Topic, Status, UnreadMessages, GroupId } = caseThatChanged;
            const queueName = await dispatch('getQueueName', GroupId);
            dispatch('addCaseToActiveUserCases', {
                CaseID,
                ClientID,
                InternalType,
                Topic,
                Status,
                UnreadMessages,
                queue: queueName,
                TypeIcon: icon || 'mdi-email',
            });
        }
    },
    getQueueName({ state }, groupId) {
        const queue = state.caseFilters.queues.find((q) => q.value === groupId);
        return queue ? queue.name : '';
    },

    goToCase(_, payload) {
        try {
            // Check if case is loadin
            if (this.caseState === states.LOADING) {
                this._vm.$toasted.show(i18n.t('cases.waitUntilCaseIsLoaded'), {
                    icon: 'alert-circle',
                    type: 'warning',
                });
                return;
            }

            const url = '/cases/' + payload.caseId;
            router.push(url);
        } catch (error) {
            console.log('error', error);
            this._vm.$toasted.show(i18n.t('cases.couldNotOpen'), {
                icon: 'alert-circle',
                type: 'warning',
            });
        }
    },

    addItemsToList({ commit, state }, payload) {
        const { totalCases, cases } = payload;

        commit('SET_CASES_DATA', { cases: [], totalCases });

        const oldCases = state.cases;
        const newCases = cases;

        const maxAmountOfPages = Math.ceil(totalCases / state.caseFiltersState.items);
        // if current selected page is higher than the max amount of pages, set page to max amount of pages
        if (state.caseFiltersState.page > maxAmountOfPages && maxAmountOfPages >= 1) {
            commit('SET_CASE_FILTER_PAGE', maxAmountOfPages > 1 ? maxAmountOfPages : 1);
        }

        if (oldCases.length === 0) {
            commit('SET_CASES_DATA', { cases, totalCases });
            return;
        }

        if (newCases.length === 0) {
            commit('SET_CASES_DATA', { cases: [], totalCases });
            return;
        }

        if (oldCases.length === newCases.length) {
            const oldCasesIds = oldCases.map((item) => item.caseId);
            const newCasesIds = newCases.map((item) => item.caseId);

            const oldCasesIdsString = oldCasesIds.join('');
            const newCasesIdsString = newCasesIds.join('');

            if (oldCasesIdsString === newCasesIdsString) {
                return;
            }
        }

        commit('SET_CASES_DATA', { cases, totalCases });
    },

    addCasesWithDelay({ commit }, { oldCases, newCases }) {
        const index = [];

        for (const [i, oldCase] of oldCases.entries()) {
            const newIndex = newCases.findIndex((item) => item.caseId === oldCase.caseId);

            const INCREMENT_INDEX = 1;
            const REMOVE_INDEX = -1;

            if (i > newIndex + INCREMENT_INDEX) {
                index.push({ from: i, to: -1 });
            } else {
                index.push({ from: i, to: newIndex });
            }

            if (newIndex === REMOVE_INDEX && oldCase.caseId === state.selectedCaseId) {
                commit('SET_REMOVED_CASE_INDEX', i);
            }
        }

        commit('SET_ANIMATION_GRID', index);
    },

    async setNewCasesData({ state, commit, dispatch }, payload) {
        const ANIMATION_DELAY = 500;
        const BETWEEN_ANIMATION_DELAY = 1000;

        const oldCases = state.cases;
        const newCases = payload.cases;

        if (!newCases) return;

        await dispatch('addCasesWithDelay', { oldCases, newCases });

        await new Promise((resolve) => setTimeout(resolve, ANIMATION_DELAY));

        if (payload.cases.length === 0) {
            await dispatch('unSubscribeToListenToCaseList', state.caseFiltersState);

            if (state.caseFiltersState.page > 1) {
                commit('SET_CASE_FILTER_PAGE', state.caseFiltersState.page - 1);
            }

            dispatch('getCases', state.caseFiltersState);
            await dispatch('subscribeToListenToCaseList', state.caseFiltersState);
        }
        commit('SET_CASES_DATA', payload);

        await new Promise((resolve) => setTimeout(resolve, BETWEEN_ANIMATION_DELAY));

        commit('SET_ANIMATION_GRID', []);
    },

    async executeAnimationGridQueue({ state, commit }) {
        if (state.animationGridQueue.length === 0) return;
        if (state.animationGrid.length > 0) return;

        const payload = state.animationGridQueue[0];
        commit('REMOVE_ANIMATION_GRID_FIRST_ITEM');

        await this.dispatch('Cases/setNewCasesData', payload);
    },

    subscribeToCases({ dispatch }) {
        dispatch('unSubscribeToActiveCases');
        dispatch('subscribeToActiveCases');
    },

    subscribeToActiveCases() {
        this._vm.$socket.emit('activeCasesSubscription');
    },

    unSubscribeToActiveCases() {
        this._vm.$socket.emit('activeCasesUnsubscription');
    },

    async setActiveUserCases({ commit, rootState, dispatch }, payload) {
        const channelsFromStore = rootState.System.userSettings.system.channels;
        const formattedConfig = getActiveFromSettings(channelsFromStore);
        const channelTypesArray = filterObjectsByKeys(formattedConfig, channelTypes, 'value');
        for (const [index, el] of payload.entries()) {
            el.activeCaseIndex = index;
            const res = channelTypesArray.find((t) => t.value === el.InternalType);
            if (!res) {
                continue;
            }
            const { icon } = res;
            el.TypeIcon = icon || 'mdi-email';
            el.queue = await dispatch('getQueueName', el.GroupId);
        }
        commit('SET_ACTIVE_USER_CASES', payload);
    },

    caseFree({ rootState, state, commit }, selectedCaseId) {
        const caseId = selectedCaseId || state.selectedCase?.case?.caseId;
        if (!caseId) return;

        // 1. Optimistic update the busyCaseList
        const busyCaseList = state.busyCaseList.filter(
            (item) =>
                item.userId !== rootState.Auth.userObject.userId ||
                item.id !== caseId ||
                item.socketId !== this._vm.$socket.id,
        );

        commit('SET_BUSY_CASE_LIST', busyCaseList);

        // 2. Remove the case from the currentCaseSubscription and emit the current case
        this._vm.$socket.emit('currentCaseUnsubscription', { id: caseId });
    },

    caseBusy({ rootState, state }, caseId) {
        if (caseId) {
            const anyUserInCase = state.busyCaseList.some((item) => item.id === caseId);

            const caseBusy = {
                id: caseId,
                userId: rootState.Auth.userObject.userId,
                userName: rootState.Auth.userObject.userName,
                socketId: this._vm.$socket.id,
                activeUser: !anyUserInCase,
            };

            this._vm.$socket.emit('currentCaseSubscription', caseBusy);
        }
    },

    editActiveUserCases({ commit }, payload) {
        commit('EDIT_ACTIVE_USER_CASES', payload);
    },

    closeCaseTab({ commit }, payload) {
        commit('CLOSE_CASE_TAB', payload);
    },
    addCaseToActiveUserCases({ commit }, payload) {
        commit('ADD_CASE_TO_ACTIVE_USER_CASES', payload);
    },
    setShowHeaders({ commit }, payload) {
        commit('SET_SHOW_HEADERS', payload);
    },

    setDateNow({ commit }, payload) {
        commit('SET_DATE_NOW', payload);
    },

    setSelectedCases({ commit }, payload) {
        commit('SET_SELECTED_CASES', payload);
    },

    // setUserAssign({ commit }, payload) {
    //     commit('SET_USER_ASSIGN', payload);
    // },

    setCategoryAssign({ commit }, payload) {
        commit('SET_CATEGORY_ASSIGN', payload);
    },

    setStatusAssign({ commit }, payload) {
        commit('SET_STATUS_ASSIGN', payload);
    },
    setQueueAssign({ commit }, payload) {
        commit('SET_QUEUE_ASSIGN', payload);
    },

    setListLayout({ commit }, payload) {
        commit('SET_LIST_LAYOUT', payload);
    },

    setGoToContent({ commit }, payload) {
        commit('SET_GO_TO_CONTENT', payload);
    },

    setSearchType({ commit }, payload) {
        commit('SET_SEARCH_TYPE', payload);
    },

    async getCaseTravel({ commit }, caseId) {
        try {
            const result = await cases.getCaseTravel(caseId);

            if (state.caseToLoad !== caseId) {
                commit('SET_CASE_TRAVEL', null);
                return null;
            }

            commit('SET_CASE_TRAVEL', result.data);
            return result.data;
        } catch (error) {
            commit('SET_CASE_TRAVEL', null);
            return error;
        }
    },

    async getMoreComments({ commit, dispatch, state, rootState }, { caseId, showSystemNotifications }) {
        const { reversedCommentsOrder, commentSearch } = rootState.Comments;

        let lastCommentId = null;
        const lastComment = state.selectedCase.comments.at(-1);
        const lastCommentDateTime = lastComment?.dateTime;
        const firstComment = state.selectedCase.comments[0];
        const firstCommentDateTime = firstComment?.dateTime;

        if (reversedCommentsOrder) {
            if (firstComment && firstComment.id !== undefined && firstComment.id !== null) {
                lastCommentId = firstComment.id;
            } else {
                return;
            }
        } else if (lastComment && lastComment.id !== undefined && lastComment.id !== null) {
            lastCommentId = lastComment.id;
        } else {
            return;
        }
        commit('SET_CASE_STATE', states.LOADING);

        await dispatch('getCaseContent', {
            caseId,
            commentId: lastCommentId,
            search: commentSearch,
            dateTime: reversedCommentsOrder ? firstCommentDateTime : lastCommentDateTime,
            showSystemNotifications,
        });
        commit('SET_CASE_STATE', states.LOADED);
    },

    async handleCaseRouting({ commit, dispatch, rootState }, caseId) {
        try {
            await dispatch('setSelectedCaseId', caseId);

            if (caseId) {
                await dispatch('isCaseBusy', caseId);
                dispatch('Comments/setIsForwarding', false, { root: true });

                commit('SET_CASE_TO_LOAD', caseId);

                async function getCaseContent() {
                    await dispatch('getCaseContent', {
                        caseId,
                        load: true,
                        showSystemNotifications: rootState.Users.userPreferences.showSystemNotifications,
                    });
                }

                async function clientCard() {
                    await dispatch('clientCard', caseId);
                }

                async function timeline() {
                    // Timeline is recieved locally on client card
                    // Maybe should refractor to be recieved here instead
                }

                async function caseTravel() {
                    commit('SET_TRAVEL_STATE', states.LOADING);
                    await dispatch('getCaseTravel', caseId);
                    commit('SET_TRAVEL_STATE', states.LOADED);
                }

                const LOADING_MIN_TIME_MS = 500;
                const loadingPromise = new Promise((resolve) => setTimeout(resolve, LOADING_MIN_TIME_MS));

                try {
                    this._vm.$toasted.clear();
                } catch (_error) {
                    // do nothing
                }

                const promises = [
                    Promise.race([getCaseContent(), loadingPromise]),
                    Promise.race([clientCard(), loadingPromise]),
                    Promise.race([timeline(), loadingPromise]),
                    Promise.race([caseTravel(), loadingPromise]),
                ];

                await Promise.all(promises);
                await commit('SET_SELECTED_CASE_ID', caseId);
                await dispatch('handleUnreadMessageResets', caseId);
            } else {
                commit('SET_SELECTED_CASE_ID', 0);
                commit('SET_SELECTED_CASE', null);
                commit('SET_CLIENT_CONTACT_INFO', null);
                commit('SET_CLIENT_ON_CARD', null);
                commit('SET_CASE_TIMELINE', null);
                commit('SET_CASE_TRAVEL', null);
                commit('SET_CASE_STATE', states.INIT);
            }
        } catch (error) {
            console.error('Could not handle case routing', error);
        }
    },

    setCaseCheckbox({ commit, state }, payload) {
        const selectedCases = [...state.selectedCases]; // create a copy of selectedCases
        const { caseId } = payload.item;
        if (payload.isChecked) {
            selectedCases.push(caseId);
        } else {
            const index = selectedCases.indexOf(caseId);
            if (index !== -1) {
                selectedCases.splice(index, 1);
            }
        }
        commit('SET_SELECTED_CASES', selectedCases);
    },

    isCaseBusy({ state, rootState }, caseId) {
        // show toasts if im already in the case or if another user is in the case
        try {
            const { userId: myUserId } = rootState.Auth.userObject;
            const caseIsEmpty = !state.busyCaseList.some((el) => el.id === caseId);
            // If the case is empty, return false
            if (caseIsEmpty) {
                return false;
            }

            const iAmAlreadyInCase = state.busyCaseList.some((el) => el.id === caseId && el.userId === myUserId);

            if (iAmAlreadyInCase) {
                // If the case is occupied by me, show a toast and return false
                this._vm.$toasted.info(i18n.t('casesStore.yourCase'), {
                    icon: 'information',
                });
                return false;
            }
            const activeUserInCase = state.busyCaseList.find((el) => el.id === caseId && el.activeUser).userName;
            // If case is either assigned to current user or neither is assigned, show a toast and return false
            const busyUserToastMessage = activeUserInCase + i18n.t('casesStore.someonesCase');
            // For some reason this does not work without this timeout - Feel free to fix if you know how - Jesper 2023-02-08
            setTimeout(() => {
                this._vm.$toasted.info(busyUserToastMessage, {
                    icon: 'information',
                    duration: 5000,
                });
            }, 200);
            return false;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.shortWrong') + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            return false;
        }
    },

    async setLocalStorageAtLogin({ state, dispatch }, reload = false) {
        // Set the default values in local storage for the cases filter menues
        if (localStorage.getItem('__casesFilter') == null || state.errorCount > 0 || reload) {
            const filters = structuredClone(state.caseFiltersState);
            filters.statuses = state.caseFilters.statuses.filter((item) => item.value === caseStatuses.OPEN);
            filters.categories = state.caseFilters.categories;
            filters.channels = state.caseFilters.channels;
            filters.users = [
                {
                    value: usersSpecialCases.ALLUSERS,
                },
            ];
            filters.queues = state.caseFilters.queues;
            filters.brands = state.caseFilters.brands;
            filters.showUnansweredOnly = state.caseFilters.showUnansweredOnly;

            await dispatch('updateSelectedFilterObject', filters);
        }

        if (state.errorCount > 0) {
            router.go();
        }
    },

    async setLocalStorageAllSelected({ state, dispatch }, reload = false) {
        // Set the default values in local storage for the cases filter menues
        if (localStorage.getItem('__casesFilter') == null || state.errorCount > 0 || reload) {
            const filters = structuredClone(state.caseFiltersState);
            filters.statuses = state.caseFilters.statuses;
            filters.categories = state.caseFilters.categories;
            filters.channels = state.caseFilters.channels;
            filters.users = [
                {
                    value: usersSpecialCases.ALLUSERS,
                },
            ];
            filters.queues = state.caseFilters.queues;
            filters.brands = state.caseFilters.brands;
            filters.showUnansweredOnly = state.caseFilters.showUnansweredOnly;

            await dispatch('updateSelectedFilterObject', filters);
        }

        if (state.errorCount > 0) {
            router.go();
        }
    },

    setLocalStorageUserSelected({ state, dispatch, rootState }, reload = false) {
        // Set the default values in local storage for the cases filter menues
        if (localStorage.getItem('__casesFilter') == null || state.errorCount > 0 || reload) {
            const filters = structuredClone(state.caseFiltersState);

            const mySelf = {
                name: rootState.Auth.userObject.userName,
                UserID: rootState.Auth.userObject.userId,
                value: rootState.Auth.userObject.userId,
            };
            filters.users = [mySelf];
            filters.statuses = state.caseFilters.statuses.filter(
                (item) => item.value === caseStatuses.OPEN || item.value === caseStatuses.ONGOING,
            );
            filters.categories = state.caseFilters.categories;
            filters.channels = state.caseFilters.channels;
            filters.queues = state.caseFilters.queues;
            filters.brands = state.caseFilters.brands;
            filters.showUnansweredOnly = state.caseFilters.showUnansweredOnly;

            dispatch('updateSelectedFilterObject', filters);
        }

        if (state.errorCount > 0) {
            router.go();
        }
    },

    createDateNowEvent({ dispatch }) {
        this.timeInterval = setInterval(() => {
            const dateNow = Date.now();
            dispatch('setDateNow', dateNow);
        }, 1000);
        const dateNow = Date.now();
        dispatch('setDateNow', dateNow);
    },

    async getCases({ dispatch, commit, state }, payload) {
        const newPayload = mapFilterPayload(state.caseFiltersState, state.searchType, payload);

        const { searchType, query } = newPayload;
        await dispatch('unSubscribeToListenToCaseList', state.caseFiltersState);

        if (state.loadingTimeout) {
            commit('CLEAR_LOADING_TIMEOUT');
        }

        const timeout = setTimeout(() => {
            commit('SET_LOADING', true);
        }, 1000);
        commit('SET_LOADING_TIMEOUT', timeout);

        // If no filters are selected, return empty array
        const isEmpty = helper.keysAreEmpty(state.caseFiltersState);

        if (isEmpty) {
            commit('SET_CASES_DATA', { cases: [], totalCases: 0 });
            commit('SET_LOADING', false);
            commit('CLEAR_LOADING_TIMEOUT');
            commit('SET_LOADING_TIMEOUT', null);
            return null;
        }
        if (!query.length) {
            await dispatch('subscribeToListenToCaseList', state.caseFiltersState);
        }

        try {
            const result = await cases.getCases(newPayload);
            if (searchType !== state.searchType && query !== state.caseFiltersState.query && query.length > 0) {
                return null;
            }

            const { data } = result;
            const maxAmountOfPages = Math.ceil(data.totalCases / state.caseFiltersState.items);
            // if current selected page is higher than the max amount of pages, set page to max amount of pages and fetch again
            // the reason why its done here and not in the beginning of the function is because when first loading the page, the page is set to 1 and totalCases is 0(which isn't correct) but we don't get the correct totalCases until we fetch the cases - Tim Aronsson 2023-02-07
            if (state.caseFiltersState.page > maxAmountOfPages && maxAmountOfPages >= 1) {
                console.warn('Current page is higher than max amount of pages, setting page to max amount of pages');
                commit('SET_CASE_FILTER_PAGE', maxAmountOfPages > 1 ? maxAmountOfPages : 1);
                // fetch again with new page
                dispatch('getCases', state.caseFiltersState);
                return null;
            }
            commit('SET_CASES_DATA', data);
            commit('SET_LOADING', false);
            commit('CLEAR_LOADING_TIMEOUT');
            commit('SET_LOADING_TIMEOUT', null);

            return data;
        } catch (error) {
            commit('SET_LOADING', false);
            commit('CLEAR_LOADING_TIMEOUT');
            commit('SET_LOADING_TIMEOUT', null);

            this._vm.$toasted.show(i18n.t('casesStore.noFetchCases') + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            throw error;
        }
    },
    getCheckedCases({ rootState, commit }, payload) {
        let allCommentsInCaseChecked = true;
        for (const comment of payload) {
            if (comment.attachChecked) continue;
            if (
                !rootState.System.userSettings.cases.attachConversationSettings?.attachSystemMessages?.active &&
                comment.internalType == null &&
                comment.typeOfMessage === 'system'
            )
                continue;
            allCommentsInCaseChecked = false;
        }
        commit('SET_ALL_COMMENTS_IN_CASE_CHECKED', allCommentsInCaseChecked);
    },

    subscribeToListenToCaseList(_, payload) {
        const data = payload;
        if (data.searchQuery.length !== 0) return;
        const roomData = {
            statuses: data.statuses.map((item) => item.value),
            limit: data.items,
            page: data.page,
            userIds: data.users.map((user) => user.value),
            queues: data.queues.map((item) => item.value).sort((a, b) => a - b),
            channels: data.channels.map((item) => item.value).sort(),
            categories: data.categories.map((item) => item.value).sort((a, b) => a - b),
            showAnswered: data.showUnansweredOnly ? 0 : 1,
            sortBy: data.sortField,
            direction: data.sortDirection,
            query: data.searchQuery,
        };

        this._vm.$socket.emit('casesInListSubscription', roomData);
    },

    unSubscribeToListenToCaseList(_, payload) {
        // this interceptor is to check if the user has selected any filters, if not there is no subscrpition to unsubscribe from

        const isEmpty = helper.keysAreEmpty(state.caseFiltersState);

        if (isEmpty) {
            return;
        }

        const data = payload;
        const roomData = {
            statuses: data.statuses.map((item) => item.value).sort((a, b) => a - b),
            limit: data.items,
            page: data.page,
            userIds: data.users.map((user) => user.value),
            queues: data.queues.map((item) => item.value).sort((a, b) => a - b),
            channels: data.channels.map((item) => item.value).sort(),
            categories: data.categories.map((item) => item.value).sort((a, b) => a - b),
            showAnswered: data.showUnansweredOnly ? 0 : 1,
            sortBy: data.sortField,
            direction: data.sortDirection,
            query: data.searchQuery,
        };
        this._vm.$socket.emit('casesInListUnsubscription', roomData);
    },

    async getCaseFilters({ commit, dispatch, state }) {
        commit('SET_FILTERS_LOADED', false);

        const brands = await dispatch('Brands/getAvailableBrandsByUser', null, { root: true });
        commit('SET_CASEFILTER_BRANDS', brands);

        let filtersToSet = structuredClone(state.caseFiltersState);

        if (typeof filtersToSet.categories === 'object' && !Array.isArray(filtersToSet.categories)) {
            filtersToSet.categories = [filtersToSet.categories];
        }

        try {
            await Promise.all([
                dispatch('getFilterBrands'),
                // dispatch('getFilterQueues'),
                // dispatch('getFilterCategories'),
                dispatch('setStatus'),
                dispatch('setChannels'),
                dispatch('setUsers'),
                dispatch('setSortFields'),
            ]);
        } catch (error) {
            console.log('error: ', error);
            Sentry.captureException(error);
            commit('SET_FILTERS_LOADED', true);
            return;
        }

        try {
            // 1. Check local storage for filters
            const filters = localStorage.getItem('__casesFilter');
            const parsedFilters = JSON.parse(filters);

            if (!Array.isArray(parsedFilters.users)) {
                throw new TypeError('Users is no array. Setting all userids in filter.');
            }

            if (!parsedFilters) {
                throw new Error('No filters in local storage');
            }
            filtersToSet = parsedFilters;
        } catch (_error) {
            // 2. If we can't get from local storage set them to all selected
            // const { statuses, channels, categories, users, queues } = state.caseFilters;
            const { statuses, channels, categories, users, queues, brands } = state.caseFilters;
            const openStatus = statuses.find((item) => item.value === caseStatuses.OPEN);
            const mappedSortField = statusTypes.find((item) => item.value === caseStatuses.OPEN).sortField;
            const mappedSortDirection = statusTypes.find((item) => item.value === caseStatuses.OPEN).sortDirection;

            filtersToSet = {
                ...filtersToSet,
                statuses: [openStatus],
                categories,
                channels,
                queues,
                users,
                sortField: mappedSortField,
                sortDirection: mappedSortDirection,
                brands,
            };
        }

        // 3. Cleanup so filters dont contain any values that are not in the filter list and set them in local storage
        dispatch('cleanupCaseFiltersState', filtersToSet);

        // 5. Reload the cases with the filters
        await Promise.allSettled([
            dispatch('DynamicStatistics/initDynamicStatistics', null, { root: true }),
            dispatch('setSearchQueryAndGetCases', state.searchQuery),
        ]);

        commit('SET_FILTERS_LOADED', true);
    },

    cleanupCaseFiltersState({ state, commit }, caseFiltersState) {
        const { caseFilters } = state;

        const keysToCompare = ['queues', 'statuses', 'categories', 'channels', 'brands'];
        // const keysToCompare = ['queues', 'statuses', 'categories', 'channels'];

        const updatedCaseFilterState = structuredClone(caseFiltersState);
        const updatedCaseFilters = structuredClone(caseFilters);

        for (const key of keysToCompare) {
            if (Array.isArray(updatedCaseFilterState[key]) && Array.isArray(updatedCaseFilters[key])) {
                updatedCaseFilterState[key] = updatedCaseFilterState[key].filter((value) =>
                    updatedCaseFilters[key].some((filterValue) => filterValue.value === value.value),
                );
            }
        }

        commit('SET_SELECTED_FILTERS', updatedCaseFilterState);
    },

    async setSearchQueryAndGetCases({ commit, dispatch }, payload) {
        commit('SET_SEARCH_QUERY', payload);

        await dispatch('updateSelectedFilterObject', { page: 1 });
    },

    async getAllQueuesByLoggedInUser({ dispatch }) {
        try {
            const response = await dispatch('QueueManager/getAllQueuesByLoggedInUser', null, { root: true });
            const queuesRaw = structuredClone(response);

            queuesRaw.unshift({
                Name: i18n.t('casesStore.noQueue'),
                ID: null,
            });

            return helper.formatQueues(queuesRaw);
        } catch (error) {
            console.error('Could not get queues by logged in user', error);
            Sentry.captureException(error);
            return [];
        }
    },

    async getQueues({ dispatch }, filters = {}) {
        try {
            const response = await dispatch('QueueManager/getQueuesV2', filters, { root: true });
            const queuesRaw = structuredClone(response);

            if (!queuesRaw) {
                throw new Error('QueueManager/getQueuesV2 returned null');
            }

            queuesRaw.unshift({
                Name: i18n.t('casesStore.noQueue'),
                ID: null,
                Brand: null,
            });

            return helper.formatQueues(queuesRaw);
        } catch (error) {
            console.error('Could not get queues', error);
            Sentry.captureException(error);
            throw error;
        }
    },
    async getFilterBrands({ commit, dispatch, rootState }) {
        // If the user has no brands or brands are not active, we need to fetch the queues
        if (!rootState.Brands.userAvailableBrands?.length || !rootState.Brands.active) {
            await dispatch('getFilterQueues');
            return;
        }

        // Make sure that the selected brands for the user are fetched
        await dispatch('Brands/getUserSelectedBrands', null, { root: true });

        const brands = rootState.Brands.userAvailableBrands.filter((brand) =>
            rootState.Brands.userSelectedBrands.includes(brand.id),
        );
        commit('SET_CASEFILTER_BRANDS', brands);

        const currentFilterBrands = rootState.Cases.caseFiltersState.brands;
        const filteredCurrentFilterBrands = currentFilterBrands.filter((brand) =>
            brands.some((b) => b.id === brand.id),
        );

        if (filteredCurrentFilterBrands.length !== currentFilterBrands.length) {
            await dispatch('updateSelectedFilterObject', {
                brands: filteredCurrentFilterBrands,
            });
        }

        await dispatch('getFilterQueues');
    },

    async getFilterQueues({ commit, dispatch, rootState }) {
        const admin = rootState.System.userSettings;
        const { userId } = rootState.Auth.userObject;

        const restrictiveQueueFilterActive = Boolean(admin?.cases?.restrictiveQueueFilter?.active);
        const restrictiveQueueChangeActive = Boolean(admin?.cases?.restrictiveQueueChange?.active);

        const userSelectedBrands = rootState.Brands.userSelectedBrands;
        const brandQueries = userSelectedBrands.length ? { brands: userSelectedBrands, includes: ['brand'] } : {};

        // Helper function to fetch queues and commit to the store
        const fetchAndCommitQueues = async (userQuery = {}) => {
            const queues = await dispatch('getQueues', { ...brandQueries, ...userQuery });
            await commit('SET_CASE_QUEUES', queues);
            await commit('SET_CASE_CHANGE_QUEUES', queues);

            return queues;
        };

        // Main conditional logic
        if (restrictiveQueueFilterActive && restrictiveQueueChangeActive) {
            const queues = await fetchAndCommitQueues({ users: [userId] });
            await dispatch(
                'getFilterCategories',
                queues.map((queue) => queue.value),
            );
            return queues;
        }

        if (!restrictiveQueueFilterActive && !restrictiveQueueChangeActive) {
            const queues = await fetchAndCommitQueues();
            await dispatch(
                'getFilterCategories',
                queues.map((queue) => queue.value),
            );
            return queues;
        }

        // For mixed scenarios
        const allQueues = await dispatch('getQueues', brandQueries);
        const userQueues = await fetchAndCommitQueues({ users: [userId] });
        await commit('SET_CASE_CHANGE_QUEUES', allQueues);

        await dispatch(
            'getFilterCategories',
            userQueues.map((queue) => queue.value),
        );
        return userQueues;
    },

    async getFilterCategories({ commit, dispatch }, queueIds) {
        try {
            const categories = await dispatch(
                'Categories/getCategories',
                { queueId: queueIds, sortBy: 'Name' },
                { root: true },
            );

            const formattedCategories = categories.map((item) => {
                const clone = structuredClone(item);
                clone.value = item.id;
                return clone;
            });

            commit('SET_CASEFILTER_CATEGORIES', formattedCategories);

            return categories;
        } catch (error) {
            console.error('Could not get categories', error);
            Sentry.captureException(error);
            throw error;
        }
    },

    async setSelectedFilters({ commit, dispatch, state }, payload) {
        try {
            await dispatch('unSubscribeToListenToCaseList', state.caseFiltersState);
            await dispatch('DynamicStatistics/unSubscribeToWallboard', null, { root: true });
            // exit select mode when filters are changed
            commit('SET_SELECTED_CASES', []);
            commit('SET_LIST_SELECT', false);

            await commit('SET_SELECTED_FILTERS', payload);

            // this interceptor is to check if the user has selected any filters, if not we  dont want to fetch cases
            const isEmpty = helper.keysAreEmpty(state.caseFiltersState);

            if (isEmpty) {
                commit('SET_CASES_DATA', { cases: [], totalCases: 0 });
                return;
            }

            // this prevents search from triggering get cases when we're resetting the page to 1 - Tim Aronsson 2023-01-18
            if (payload.skipGetCases) {
                delete payload.skipGetCases;
                await commit('SET_SELECTED_FILTERS', payload); // we need to set the filters again to remove the skipGetCases property to not cause any issues later - Tim Aronsson 2023-01-18
                return;
            }
            await dispatch('getCases', state.caseFiltersState);
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.noFetchCases') + error, {
                icon: 'mdi-alert-circle',
                type: 'error',
            });
            throw error;
        }
    },

    handleUnreadMessageResets({ state, dispatch }, caseId) {
        if (state.selectedCase?.case?.unreadMessages === 0) return;
        if (state.selectedCase?.case?.caseId !== caseId) return; // This is to minimize the amount of requests to the backend if a user is spamming the case list

        dispatch('resetUnreadMessages', caseId);
    },

    setSelectedCaseId({ commit }, payload) {
        commit('SET_SELECTED_CASE_ID', payload);
    },
    setSelectedStatus({ commit }, payload) {
        commit('SET_SELECTED_STATUS', payload);
    },

    setSelectedChannels({ commit }, payload) {
        commit('SET_SELECTED_CHANNELS', payload);
    },

    toggleFilters({ commit }, payload) {
        commit('SET_SHOW_FILTERS', payload);
    },

    setSelectedCategories({ commit }, payload) {
        commit('SET_SELECTED_CATEGORIES', payload);
    },

    setCategories({ commit }, payload) {
        commit('SET_CASE_CATEGORIES', payload);
    },

    setChannels({ rootState, commit }) {
        const channelsFromMongo = rootState.System.userSettings.system.channels;

        const formattedConfig = getActiveFromSettings(channelsFromMongo);
        const activeChannelsData = filterObjectsByKeys(formattedConfig, channelTypes, 'value');
        commit('SET_CASE_CHANNELS', activeChannelsData);
    },

    setUsers({ commit }) {
        commit('SET_CASE_USERS');
    },

    setStatus({ commit, rootState }) {
        const mongoStatuses = rootState.System.userSettings.system.statuses;
        const formattedConfig = getActiveFromSettings(mongoStatuses);
        const filteredStatuses = filterObjectsByKeys(formattedConfig, statusTypes, 'value');
        commit('SET_CASE_STATUS', filteredStatuses);
    },

    setSortFields({ commit, rootState }) {
        const sortField = [];
        const config = rootState.System.userSettings.cases.headers;
        const formattedConfig = getActiveFromSettings(config);
        const headers = filterObjectsByKeys(formattedConfig, casesHeaders, 'id');

        for (const item of headers) {
            sortField.push({
                value: item.value,
                color: 'var(--v-gray2-base)',
                icon: 'mdi-sort',
            });
        }

        commit('SET_CASE_SORT_FIELDS', sortField);
        return sortField;
    },

    setQueues({ commit }, payload) {
        commit('SET_CASE_QUEUES', payload);
    },

    setCaseData({ commit, dispatch }, payload) {
        dispatch('getCheckedCases', payload);
        commit('SET_CASE_DATA', payload);
    },

    setMoveCase({ commit }, payload) {
        commit('SET_MOVE_CASE', payload);
    },
    setListSelect({ commit }, payload) {
        commit('SET_LIST_SELECT', payload);
        commit('SET_SELECTED_CASES', []);
    },
    triggerNormalAlert({ commit }, payload) {
        commit('TRIGGER_NORMAL_ALERT', payload);
    },
    callbackCallNow({ commit }, payload) {
        commit('SET_CALL_NOW_TOGGLE', payload);
    },
    openCommunication({ commit }, payload) {
        commit('SET_COMMUNICTATION_TOGGLE', payload);
    },
    sideBarView({ commit }, payload) {
        commit('SET_SIDEBAR_VIEW', payload);
    },
    /**
     * Appends a template to the text field by replacing placeholders with actual values.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Function} context.commit - The Vuex commit function.
     * @param {Object} payload - The payload containing the template content.
     * @param {string} payload.content - The template content to be appended.
     */
    async appendTemplateToTextField({ commit, dispatch }, template) {
        const content = await dispatch(
            'Templates/transformContentMergeTags',
            { template, context: 'CASES' },
            { root: true },
        );
        commit('SET_TEMPLATE_TEXT', content);
    },

    setCommunicationCreatorData({ commit, dispatch }, payload) {
        commit('SET_COMMUNICATION_CREATOR_DATA', payload);
        dispatch('openCommunication', true);
    },
    commentView({ commit }, payload) {
        commit('SET_COMMENT_VIEW', payload);
    },
    divertConversation({ dispatch, commit }, payload) {
        // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
        return new Promise(async (resolve, reject) => {
            const amountLeft = await dispatch('ActiveCases/getActiveCapacityLeft', payload.internalType, {
                root: true,
            });

            if (amountLeft < 0 || !amountLeft) {
                this._vm.$toasted.show(i18n.t('casesStore.noCapacityLeft'), {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                    duration: 10000,
                    action: {
                        text: i18n.t('casesStore.seeActiveCases'),
                        class: 'white-text',
                        onClick: (_e, toastObject) => {
                            commit('Users/SET_USER_ACTION_MODULE_OPEN', true, { root: true });
                            toastObject.goAway(0);
                        },
                    },
                });
                reject();
                return;
            }
            cases
                .setDivert(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setInQueueTimer({ commit }) {
        return new Promise((resolve, reject) => {
            cases
                .getBackendTime()
                .then((res) => {
                    commit('SET_IN_QUEUE_TIMER', res.data.time);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getSystemEmails({ commit }) {
        try {
            const resolve = await cases.getSystemEmails();
            commit('SET_SYSTEM_EMAILS', resolve.data);
            return resolve.data;
        } catch (error) {
            return error;
        }
    },

    async getSystemSmsNumbers({ commit }) {
        try {
            const resolve = await cases.getSystemPhoneNumbers();
            commit('SET_SYSTEM_SMS_NUMBERS', resolve.data);
            return resolve.data;
        } catch (error) {
            return error;
        }
    },
    soapGetUserLink(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .soapGetUserLink(payload)
                .then((res) => {
                    resolve(res.data.GetPersonResult);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getCaseByMsgID(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .getCaseByMsgID(payload)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    search(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .search(payload)
                .then((res) => {
                    resolve(res.data.result);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    async timeline({ commit }, { id, load = true }) {
        try {
            const result = await cases.timeline(id);

            if (state.caseToLoad !== id && load !== false) {
                throw new Error('Case has changed');
            }

            commit('SET_CASE_TIMELINE', result.data);
            return result.data;
        } catch (error) {
            commit('SET_CASE_TIMELINE', null);
            return error;
        }
    },

    async getTimelineItems({ state, commit }, { limit, page, caseId }) {
        try {
            const response = await cases.getTimelineItems({ limit, page, caseId });

            if (state.selectedCase.case.caseId !== caseId) return;

            if (page === 1) {
                commit('SET_TIMELINE_ITEMS', response.data);
            } else {
                commit('SET_TIMELINE_ITEMS', state.timelineItems.concat(response.data));
            }

            return response;
        } catch (error) {
            console.error('Error getting timeline items', error);
        }
    },

    templateUsed(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .templateUsed(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    sendComment(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .sendComment(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('casesStore.sent'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });

                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.messNotSent'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getEmailAddresses(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .getEmailAddresses(payload)
                .then((res) => {
                    const { data } = res;
                    const emailsFromKeys = extractValidValuesFromObject(data, (v) => mailRegEx.test(v));
                    const emailsFromArray = extractValidValuesFromArray(
                        data.Emails,
                        (v) => mailRegEx.test(v.Email),
                        'Email',
                    );
                    const emails = [...new Set([...emailsFromKeys, ...emailsFromArray])];
                    resolve(emails);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async searchPhoneNumbers(_, searchQuery) {
        try {
            return await cases.searchPhoneNumbers(searchQuery);
        } catch (_error) {
            return null;
        }
    },
    attempt(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .attempt(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, { icon: 'cancel', type: 'error' });
                    reject(error);
                });
        });
    },

    updateCaseFields(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .updateCaseFields(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('casesStore.caseUpdated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });

                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    async updateCase({ commit, state }, { payload, item }) {
        // We need to use optimistic update the front end for the data to be correct in the store
        const selectedCase = structuredClone(state.selectedCase);
        if (selectedCase) {
            for (const key in payload.changed) {
                // Should solve this in a better way (shouldnt be needed to make exceptions?)
                if (key === caseKeys.CATEGORIES) {
                    selectedCase.case.categories = item.categories;
                    continue;
                }

                if (key === caseKeys.USER) {
                    selectedCase.case.user = {
                        UserID: payload.changed[key],
                        Name: item.Name,
                        value: payload.changed[key],
                    };

                    selectedCase.case.userId = payload.changed[key];
                    selectedCase.case.userName = item.Name;

                    continue;
                }

                selectedCase.case[key] = payload.changed[key];
            }

            // We should optimistically update the case list as well only what changed in the case -- work in progress
            // Need to change the way we recieve data from the backend so we got all data to set the case correctly
            // This will speed up the application a lot and make it more responsive

            /*
            const cases = structuredClone(state.cases);
            const index = cases.findIndex((item) => item.caseId === selectedCase.case.caseId);

            cases[index] = { ...cases[index], ...payload.changed };

            const changedKeysToExlude = ['categories'];

            if (index !== -1 && !changedKeysToExlude.some((item) => Object.keys(payload.changed).includes(item))) {
                commit('SET_CASES_DATA', { cases, totalCases: state.totalCases });
            }
            */

            commit('SET_SELECTED_CASE', selectedCase);
        }

        try {
            const res = await cases.updateCase(payload);
            if (payload.changed.status === caseStatuses.DELETED) {
                this._vm.$toasted.show(i18n.t('casesStore.deleteScheduled'), {
                    icon: 'mdi-content-save',
                    type: 'success',
                });
                return res.data;
            }
            this._vm.$toasted.show(i18n.t('casesStore.caseUpdated'), { icon: 'mdi-content-save', type: 'success' });
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async deleteCase(_, payload) {
        await cases.deleteCase(payload);
    },

    async deleteCases({ dispatch, commit }, payload) {
        const MIN = 1;
        const MAX = 250;
        const { caseIds } = payload;
        if (caseIds.length < MIN || caseIds.length > MAX) {
            throw new Error('The number of cases to delete must be between 1 and 250');
        }
        await cases.deleteCases(payload);
        dispatch('setListSelect', '');
        commit('SET_CATEGORY_ASSIGN', []);
    },

    applyActionToMultipleCases({ state, dispatch, commit }, assignedValue) {
        let payload = {};
        switch (state.showSelect) {
            case 'user': {
                const userId = assignedValue?.value[0];
                payload = {
                    value: { userId },
                    cases: state.selectedCases,
                };
                break;
            }
            case 'status': {
                payload = {
                    value: { status: assignedValue?.value },
                    cases: state.selectedCases,
                };
                break;
            }
            case 'category': {
                const categoryIds = assignedValue?.value;
                payload = {
                    value: { categories: categoryIds },
                    cases: state.selectedCases,
                };
                break;
            }
            case 'queue': {
                const queueId = assignedValue?.value;
                payload = {
                    value: { queueId },
                    cases: state.selectedCases,
                };
                break;
            }
            default: {
                break;
            }
        }
        return new Promise((resolve, reject) => {
            cases
                .applyActionToMultipleCases(payload)
                .then((res) => {
                    this._vm.$toasted.show(i18n.t('casesStore.casesChanged'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    dispatch('setListSelect', '');
                    commit('SET_CATEGORY_ASSIGN', []);
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    sortCases({ commit }, payload) {
        commit('SET_CASE_SORTING', payload);
    },
    setSorting({ commit }, payload) {
        commit('SET_CASE_SORTING', payload);
    },
    sortPaginations({ commit }, payload) {
        commit('SET_PAGINATION_SORTING', payload);
    },

    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
    async getCaseContent(
        { state, rootState, commit },
        { caseId, load, commentId, search, dateTime, itemsPerPage = null, showSystemNotifications },
    ) {
        try {
            const payload = { caseId, commentId, search, itemsPerPage, dateTime, showSystemNotifications };

            if (load) {
                commit('SET_CASE_STATE', states.LOADING);
            }

            const result = await cases.getSpecificCase(payload);

            if (state.caseToLoad !== result.data.case.caseId) {
                return null;
            }

            // Check if the last comments are different from the comments in the case
            const newComments = result.data.comments || []; // Use an empty array if comments are initially undefined
            const { hasMoreComments } = result.data;

            if (!commentId) {
                commit('SET_SELECTED_CASE', result.data);
            } else {
                commit('SET_SELECTED_CASE_HAS_MORE_COMMENTS', hasMoreComments);

                const uniqueNewComments = newComments.filter((newComment) => {
                    return !state.selectedCase.comments.some((existingComment) => existingComment.id === newComment.id);
                });

                if (uniqueNewComments.length > 0) {
                    // If we got reversed order direction we need to add the comments to the beginning of the array
                    const { reversedCommentsOrder } = rootState.Comments;

                    if (reversedCommentsOrder) {
                        const scrollContainer = document.querySelector('#conversation-container');
                        // Get the scroll inner content height before we push the comments
                        const scrollContainerHeightBefore = scrollContainer.scrollHeight;
                        commit('SET_SELECTED_CASE_UNSHIFT_COMMENTS', { comments: uniqueNewComments, hasMoreComments });

                        this._vm.$nextTick(() => {
                            // We need to calculate the new contents height after we push the comments
                            const scrollContainerHeightAfter = scrollContainer.scrollHeight;

                            // Calculate the height we need to scroll to
                            const scrollContainerHeightToScrollTo =
                                scrollContainerHeightAfter - scrollContainerHeightBefore;

                            scrollContainer.scrollTop = scrollContainerHeightToScrollTo;
                        });
                    } else {
                        commit('SET_SELECTED_CASE_PUSH_COMMENTS', { comments: uniqueNewComments, hasMoreComments });
                    }
                }
            }

            commit('SET_CASE_STATE', states.LOADED);

            return result.data;
        } catch (error) {
            // If response status is 403 we are not allowed to view the case
            if (error.response && error.response.status === 403) {
                commit('SET_SELECTED_CASE', null);
                commit('SET_CASE_STATE', states.NOT_ALLOWED);
                return null;
            }

            if (error.response && error.response.data.status === 404) {
                commit('SET_SELECTED_CASE', null);
                commit('SET_CASE_STATE', states.NOT_FOUND);
                return null;
            }
            commit('SET_SELECTED_CASE', null);
            commit('SET_CASE_STATE', states.ERROR);
            return error;
        }
    },

    getAllClients(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .getAllClients(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async clientCard({ commit, state, dispatch }, caseId) {
        try {
            /*
            Should maybe add this later?
            commit('SET_CLIENT_STATE', states.LOADING);
            */
            const res = await client.getClientByCaseId(caseId);

            if (state.caseToLoad !== res.data.caseId) {
                return null;
            }
            commit('SET_CLIENT_ON_CARD', res.data);
            dispatch('setClientContactInfo', 'contactPerson');
            commit('SET_CLIENT_STATE', states.LOADED);

            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 404) {
                commit('SET_CLIENT_ON_CARD', null);
                commit('SET_CLIENT_STATE', states.NOT_FOUND);
                return null;
            }
            commit('SET_CLIENT_ON_CARD', null);
            commit('SET_CLIENT_STATE', states.ERROR);
            return error;
        }
    },

    setClientContactInfo({ commit }, payload) {
        const newPayload = structuredClone(payload);
        commit('SET_CLIENT_CONTACT_INFO', newPayload);
    },

    changeCaseOwnerAndClientConnection(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .changeCaseOwnerAndClientConnection(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    changeCaseOwner(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .changeCaseOwner(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getClientContactPersons(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .getClientContactPersons(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteContactPerson(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .deleteContactPerson(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    cases({ commit }, payload) {
        return new Promise((resolve, reject) => {
            cases
                .cases(payload)
                .then((res) => {
                    commit('SET_CASES_LIST', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateClientInfo({ state, dispatch, commit }, payload) {
        return new Promise((resolve, reject) => {
            cases
                .updateClientInfo(payload)
                .then(async (res) => {
                    this._vm.$toasted.show(i18n.t('casesStore.contactsSaved'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });

                    commit('SET_CLIENT_STATE', states.LOADING);
                    if (state.selectedCase) {
                        await dispatch('clientCard', state.selectedCase.caseStart.CaseID);
                    }
                    commit('SET_CLIENT_STATE', states.LOADED);

                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    forwardCaseComments(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .forwardCaseComments(payload)
                .then((res) => {
                    if (res.status !== 200) {
                        this._vm.$toasted.show(i18n.t('casesStore.notForwarded'), {
                            icon: 'cancel',
                            type: 'error',
                        });
                        reject(res);
                        return;
                    }
                    this._vm.$toasted.show(i18n.t('casesStore.forwarded'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                    resolve(res);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },

    getSoundComments(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .getSoundComments(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    moveCase(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .moveCase(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getChatConfig() {
        return new Promise((resolve, reject) => {
            cases
                .getChatConfig()
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    searchType({ commit }, payload) {
        commit('SET_SEARCHTYPE', payload);
    },

    getActiveCasesByUser({ dispatch }) {
        return new Promise((resolve, reject) => {
            cases
                .getActiveCasesByUser()
                .then((res) => {
                    dispatch('setActiveUserCases', res.data);

                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    sendMultiCommuncation(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .sendMultiCommuncation(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getUnreadMessagesByStatus(_, payload) {
        return new Promise((resolve, reject) => {
            cases
                .getUnreadMessagesByStatus(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    getUnreadMessagesAmountByStatus(_, status) {
        return new Promise((resolve, reject) => {
            cases
                .getUnreadMessagesAmountByStatus(status)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                        icon: 'cancel',
                        type: 'error',
                    });
                    reject(error);
                });
        });
    },
    setClientOnCard({ commit }, payload) {
        commit('SET_CLIENT_ON_CARD', payload);
    },

    setSelectedSignature({ commit }, signature) {
        commit('SET_SELECTED_SIGNATURE', signature);
    },
    async getCaseSignature({ dispatch, rootState }, caseItem) {
        if (!rootState.Admin.signatureTemplates.length > 0) {
            await dispatch('Admin/getTemplates', 3, { root: true });
        }
        // Add signature if exists, in order=> aready applied > queue-specific > global > no signature
        const existingSignature = findSignature(
            caseItem.caseId,
            rootState.Admin.signatureTemplates,
            rootState.Comments.isForwarding,
        );
        if (existingSignature) {
            await dispatch('setSelectedSignature', existingSignature);
            return;
        }
        if (caseItem.queueId) {
            const [queueSignature] = await dispatch('QueueManager/getQueueDefaultSignature', caseItem.queueId, {
                root: true,
            });

            if (queueSignature && Object.keys(queueSignature).length) {
                await dispatch('setSelectedSignature', queueSignature);
                return;
            }
        }
        const globalSignature = await dispatch('Admin/getGlobalSignature', {}, { root: true });
        await dispatch('setSelectedSignature', globalSignature || {});
    },
    setHeaderLock({ commit }, val) {
        commit('SET_HEADER_LOCK', val);
    },

    async updateSelectedFilterObject({ dispatch, state }, keysToChange) {
        const updatedFilters = { ...structuredClone(state.caseFiltersState), ...keysToChange };
        await dispatch('setSelectedFilters', updatedFilters);
    },

    async createCaseCategory(_, payload) {
        try {
            const res = await cases.createCaseCategory(payload);
            this._vm.$toasted.show(i18n.t('adminStore.categoryCreated'), res);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.categoryCreationFailed'), {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getCaseCategories({ commit }) {
        try {
            const res = await cases.getCaseCategories();

            const categories = res.data.map((item) => {
                const clone = structuredClone(item);
                clone.value = item.id;
                return clone;
            });

            commit('SET_CASE_CATEGORIES', categories);

            return res.data;
        } catch (error) {
            throw new Error('Could not retrieve categories', error);
        }
    },
    async editCaseCategory(_, payload) {
        try {
            const res = await cases.editCaseCategory(payload);
            this._vm.$toasted.show(i18n.t('adminStore.saved'), res);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async deleteCaseCategory(_, payload) {
        try {
            const res = await cases.deleteCaseCategory(payload);
            this._vm.$toasted.show(i18n.t('adminStore.deleted'), res);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async replaceCaseCategory(_, payload) {
        try {
            const res = await cases.replaceCaseCategory(payload);
            this._vm.$toasted.show(i18n.t('adminStore.saved'), res);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    setIframe({ commit }, payload) {
        commit('SET_SELECTED_IFRAME', payload);
    },
    async getIframe(_, payload) {
        try {
            const res = await cases.getIframe(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async getIframes() {
        try {
            const res = await cases.getIntegrationIframes();
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    updateCasesListHeaders({ dispatch, state, rootState }, width) {
        if (!width) return;
        const headersAmount = helper.getOptimalHeadersAmount(width);
        if (state.listHeaders.currentBreakpoint === headersAmount && width !== undefined) return;

        const headersConfig = rootState.System.userSettings.cases.headers;
        const headers = helper.getFilteredHeaders(headersConfig, headersAmount);
        dispatch('setListHeaders', { headers });
    },
    setListHeaders({ commit }, payload) {
        const headers = { ...state.headers, ...payload };
        commit('SET_LIST_HEADERS', headers);
    },

    async updateCaseCategories(_, payload) {
        try {
            const res = await cases.updateCaseCategories(payload);
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('adminStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    addCommentAttachment({ commit }, payload) {
        commit('CLEAR_ATTACHMENT_TO_ADD');
        commit('SET_ATTACHMENT_TO_ADD', payload);
    },

    async startVideoMeeting(_, payload) {
        const { data } = await cases.startVideoMeeting(payload);
        return data;
    },

    isContactButtonReadOnly({ state }, item) {
        const isCallButton = item.value === caseTypes.CALL;
        const inQueue = state.selectedCase?.case?.status === caseStatuses.IN_QUEUE;
        const isCall = state.selectedCase?.case?.internalType === caseTypes.CALL;
        return isCallButton && inQueue && isCall;
    },

    giveWriteAccessToCaseOwner(_, caseId) {
        try {
            const { data } = cases.giveWriteAccessToCaseOwner(caseId);
            return data;
        } catch (error) {
            this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    async resetUnreadMessages(_, caseId) {
        try {
            if (!caseId) return;
            await cases.resetUnreadMessages(caseId);
        } catch (error) {
            const forbidden = error.response.status === httpStatusCode.FORBIDDEN;
            if (forbidden) {
                return; // We don't want to show a toast if the user doesn't have access to reset the unread messages
            }
            this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },

    async caseToPdf({ rootState, dispatch }) {
        // 1. Get all comments in the current case
        const payload = {
            caseId: rootState.Cases.selectedCase.case.caseId,
            load: false,
            commentId: null,
            search: null,
            itemsPerPage: 100,
        };

        const caseData = await dispatch('Cases/getCaseContent', payload, { root: true });

        const filteredComments = caseData.comments.filter((comment) => comment.typeOfMessage === caseTypes.EMAIL);

        // take the current content form the tip tap and add it to comment content
        let commentContent = rootState.Comments.content;

        if (filteredComments.length > 0) {
            const commentStructure = [
                '<br><hr>',
                '<br>',
                '<p><strong>{fromTitle}:</strong> {from}</p>',
                '<p><strong>{sendTitle}:</strong> {dateTime}</p>',
                '<p><strong>{toTitle}:</strong> {to}</p>',
                '<p><strong>{subjectTitle}:</strong> {subject}</p>',
                '<br>',
                `<p style="padding: 10px 0px">{comment}</p>`,
            ];

            for (const comment of filteredComments) {
                let isFirstComment = true;

                // Add a page break before each comment
                const pageBreakDiv = ['<div style="page-break-inside: avoid; break-inside: avoid">', '</div>'];
                commentContent += pageBreakDiv[0];

                for (const template of commentStructure) {
                    const replacedTemplate = template
                        .replace('{from}', comment.from)
                        .replace('{dateTime}', comment.dateTime.replace('T', ' ').slice(0, 19))
                        .replace('{to}', commentsHelper.convertToString(comment.to, comment.ccTo, comment.bccTo))
                        .replace('{subject}', comment.subject)
                        .replace('{comment}', comment.comment)
                        .replace('{fromTitle}', i18n.t('comment.from'))
                        .replace('{sendTitle}', i18n.t('comment.send'))
                        .replace('{toTitle}', i18n.t('comment.to'))
                        .replace('{subjectTitle}', i18n.t('comment.subject'));

                    if (!isFirstComment && template === '<br><hr>') {
                        commentContent += '<hr>';
                    } else {
                        isFirstComment = false;
                    }
                    commentContent += replacedTemplate;
                }
                // Add a closing div tag for page break after each comment
                commentContent += pageBreakDiv[1];
            }
        }

        // 1. We need to grab all inline attachments and add them to the inline data we are about to send
        const div = document.createElement('div');
        div.innerHTML = commentContent;

        const hydratedElement = await hydrateImages(div);
        commentContent = hydratedElement.innerHTML;

        const title = caseData.case.topic || 'Print Title';

        const content = `<html><head><title>${title}</title></head><body>${commentContent}</body></html>`;

        // Create an iframe that we set the content to then print it
        const printFrame = document.createElement('iframe');
        printFrame.setAttribute('cy-data', 'pdfIframe');
        printFrame.name = 'printFrame';
        printFrame.style.position = 'absolute';
        printFrame.style.top = '-1000000px';
        // set content to const content
        printFrame.srcdoc = content;
        document.body.append(printFrame);

        printFrame.contentWindow.print();

        printFrame.contentWindow.addEventListener('afterprint', () => {
            printFrame.remove();
        });
    },

    async getCaseAttachments({ commit, state }, caseId) {
        try {
            const { data } = await cases.getCaseAttachments(caseId);
            const caseWithAttachments = { ...state.selectedCase, attachments: data };
            commit('SET_SELECTED_CASE', caseWithAttachments);
        } catch (error) {
            // Could not fetch attachments TODO:
            this._vm.$toasted.show(i18n.t('casesStore.wrong'), error, {
                icon: 'cancel',
                type: 'error',
            });
            throw error;
        }
    },
    selectOrderIntegration({ commit }, payload) {
        commit('SET_SELECTED_ORDER_INTEGRATION', payload);
    },
    async getCallbackNumbersFromCase(_, caseId) {
        try {
            const { data } = await cases.getCallbackNumbersFromCase(caseId);
            return data;
        } catch (_error) {
            return null;
        }
    },
};
// Mutations
const mutations = {
    SET_TIMELINE_ITEMS(state, payload) {
        state.timelineItems = payload;
    },

    SET_SELECTED_ORDER_INTEGRATION(state, payload) {
        state.selectedOrderIntegration = payload;
    },
    SET_SELECTED_CASE_HAS_MORE_COMMENTS(state, payload) {
        state.selectedCase.hasMoreComments = payload;
    },
    CLEAR_ATTACHMENT_TO_ADD() {
        state.attachmentToAdd = null;
    },
    SET_ATTACHMENT_TO_ADD(state, payload) {
        state.attachmentToAdd = payload;
    },

    SET_SELECTED_IFRAME(state, payload) {
        state.selectedIframe = payload;
    },
    SET_LIST_HEADERS(state, payload) {
        state.listHeaders = payload;
    },

    SET_SELECTED_FILTERS_KEY(state, payload) {
        const { key, item } = payload;

        state.caseFiltersState[key] = item;
    },

    SET_FILTERS_LOADED(state, payload) {
        state.filtersLoaded = payload;
    },

    SET_CLIENT_CONTACT_INFO(state, payload) {
        state.clientContactInfo = payload;
    },

    SET_TRAVEL_STATE(state, payload) {
        state.travelState = payload;
    },

    SET_HISTORY_STATE(state, payload) {
        state.historyState = payload;
    },

    SET_CASE_STATE(state, payload) {
        state.caseState = payload;
    },

    SET_CLIENT_STATE(state, payload) {
        state.clientState = payload;
    },

    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_LOADING_TIMEOUT(state, payload) {
        state.loadingTimeout = payload;
    },
    CLEAR_LOADING_TIMEOUT(state) {
        clearTimeout(state.loadingTimeout);
    },
    INCREASE_IN_QUEUE_TIMER(state) {
        state.inQueueTimer += 1000;
    },
    SET_IN_QUEUE_TIMER(state, payload) {
        state.inQueueTimer = payload;
    },
    SET_CASE_TRAVEL_STATE(state, payload) {
        state.caseTravelState = payload;
        localStorage.setItem('caseTravelState', payload);
    },
    SET_CASE_TRAVEL_MINIMIZED(state, payload) {
        state.caseTravelMinimized = payload;
    },
    resetCasesList(state) {
        state.cases = [];
        state.totalCases = 0;
    },
    SET_CASE_DATA(state, payload) {
        state.caseData = payload;
    },
    setReloadCaseContent(state, payload) {
        state.reloadCaseContent = payload;
    },
    setCloseChat(state, payload) {
        state.closeChat = payload;
    },

    addCaseToCasesInQueue(state, payload) {
        state.casesInQueue.push(payload);
    },
    removeCaseFromCasesInQueue(state, payload) {
        state.casesInQueue = state.casesInQueue.filter((item) => item !== payload);
    },

    // SET_USER_ASSIGN(state, payload) {
    //     state.selectedUserAssign = payload;
    // },

    SET_CATEGORY_ASSIGN(state, payload) {
        state.selectedCategoryAssign = payload;
    },

    SET_STATUS_ASSIGN(state, payload) {
        state.selectedStatusAssign = payload;
    },
    // SET_QUEUE_ASSIGN(state, payload) {
    //     state.selectedQueueAssign = payload;
    // },

    SET_LIST_LAYOUT(state, payload) {
        localStorage.setItem('__listLayout', JSON.stringify(payload));
        state.listLayout = payload;
    },

    SET_SELECTED_CASE(state, payload) {
        // Do not update selectedCase if the user do not actually have the case open
        if (payload?.case.caseId !== router.currentRoute.params.caseId) {
            return;
        }
        state.selectedCase = payload;
        if (payload?.case.caseId) {
            state.selectedCaseId = payload.case.caseId;
        }
    },

    SET_CASE_TRAVEL(state, payload) {
        state.caseTravel = payload;
    },

    SET_SELECTED_CASE_ID(state, payload) {
        state.selectedCaseId = payload;
    },

    SET_SEARCH_QUERY(state, payload) {
        state.caseFiltersState.searchQuery = payload;
        state.searchQuery = payload;
    },

    POP_CASE(state) {
        state.cases.pop();
    },

    SET_REMOVED_CASE_INDEX(state, payload) {
        state.removedCaseIndex = payload;
    },

    SET_ANIMATION_GRID(state, payload) {
        state.animationGrid = payload;
    },

    SET_BUSY_CASES(state, data) {
        const { payload } = data;
        const { userId } = data;
        if (payload.length > 0) {
            for (const element of payload) {
                const pos = state.cases.map((e) => e.CaseID).indexOf(element.id);
                if (pos !== -1) {
                    const html = element.userName;
                    if (element.userId === userId) {
                        state.cases[pos].Active = true;
                    }
                    state.cases[pos].Busy = html;
                    state.cases = state.cases.map(
                        (obj) => [state.cases[pos]].find((o) => o.CaseID === obj.CaseID) || obj,
                    );
                }
            }
        }
    },
    SET_CURRENT_PAGE(state, payload) {
        state.currentPage = payload;
    },

    CLEAN_PRESENCE_IN_CASE_LIST(state) {
        const newCases = [];
        for (const el of state.cases) {
            delete el.Busy;
            el.Active = false;
            newCases.push(el);
        }
        state.cases = newCases;
    },

    SET_LIST_ADD_ANIMATION(state, payload) {
        state.listAddAnimation = payload;
    },
    SET_DATE_NOW(state, payload) {
        state.dateNow = payload;
    },

    SET_SELECTED_CASES(state, payload) {
        state.selectedCases = payload;
    },

    SET_BUSY_CASE_LIST(state, payload) {
        state.busyCaseList = payload;
    },

    SELECTED_CASE_BUSY(state, payload) {
        state.selectedCaseBusy = payload;
    },

    FILTER_CASE_LIST(state, payload) {
        state.cases = state.cases.map((x) => {
            const item = payload.data.find(({ CaseID }) => CaseID === x.CaseID);
            return item || x;
        });
    },
    DELETE_CASE_BY_INDEX(state, payload) {
        state.cases.splice(payload, 1);
    },
    PUSH_CASE(state, payload) {
        state.cases.push(payload);
    },

    UNSHIFT_CASE(state, payload) {
        state.cases.unshift(payload);
    },

    SET_CASE_FILTER_PAGE(state, payload) {
        state.caseFiltersState.page = payload;
    },

    SET_SELECTED_FILTERS(state, payload) {
        if (payload.page === state.caseFiltersState.page && payload.page === 0) {
            state.caseFiltersState = payload;
            state.caseFiltersState.page = 1;
        } else {
            state.caseFiltersState = payload;
        }
        localStorage.setItem('__casesFilter', JSON.stringify(state.caseFiltersState));
    },
    SET_CASE_USERS(state) {
        state.caseFilters.users = [
            {
                value: usersSpecialCases.ALLUSERS,
            },
        ];
    },

    SET_CASE_CHANNELS(state, payload) {
        state.caseFilters.channels = payload;
    },

    SET_CASE_STATUS(state, payload) {
        state.caseFilters.statuses = payload;
    },

    SET_CASE_SORT_FIELDS(state, payload) {
        state.caseFilters.sortField = payload;
    },

    SET_CASE_QUEUES(state, payload) {
        state.caseFilters.queues = payload;
    },

    SET_CASE_CHANGE_QUEUES(state, payload) {
        state.changeQueues = payload;
    },

    SET_CASEFILTER_CATEGORIES(state, payload) {
        state.caseFilters.categories = payload;
    },
    SET_CASEFILTER_BRANDS(state, payload) {
        state.caseFilters.brands = payload;
    },
    SET_CASE_CATEGORIES(state, payload) {
        state.categories = payload;
    },
    SET_SHOW_HEADERS(state, payload) {
        state.showHeaders = payload;
    },

    SET_ACTIVE_USER_CASES(state, data) {
        state.activeUserCases = data;
    },
    EDIT_ACTIVE_USER_CASES(state, data) {
        // state.activeUserCases.splice(data.oldIndex, 1)
        if (data.pagesToLeft === 0 && data.pagesToRight > 0) {
            // Längst till vänster
            state.activeUserCases.splice(data.pagesToLeft, data.activeUserCases.length);
            state.activeUserCases = [...data.activeUserCases, ...state.activeUserCases];
        } else if (data.pagesToLeft > 0 && data.pagesToRight === 0) {
            // Längst till höger
            state.activeUserCases.splice(data.pagesToLeft, data.activeUserCases.length);
            state.activeUserCases.push(...data.activeUserCases);
        } else if (data.pagesToLeft > 0 && data.pagesToRight > 0) {
            // I mitten
            const myArr = state.activeUserCases.splice(
                state.activeUserCases.length - data.pagesToRight,
                data.pagesToRight,
            );
            state.activeUserCases.splice(data.pagesToLeft, data.activeUserCases.length);
            state.activeUserCases.push(...data.activeUserCases, ...myArr);
        } else {
            // Om inga sidor
            state.activeUserCases = data.activeUserCases;
        }
        // Uppdatera activeCaseIndex som behövs när det läggs till eller tas bort ett case
        for (const [index, el] of state.activeUserCases.entries()) {
            el.activeCaseIndex = index;
        }
    },
    CLOSE_CASE_TAB(state, caseId) {
        state.activeUserCases = state.activeUserCases.filter((el) => el.CaseID !== caseId);
        for (const [index, el] of state.activeUserCases.entries()) {
            el.activeCaseIndex = index;
        }
    },
    ADD_CASE_TO_ACTIVE_USER_CASES(state, data) {
        let sameCase = false;
        for (const activeCase of state.activeUserCases) {
            /* activeUserCases already contains the case we're about to ad */
            if (activeCase.CaseID === data.CaseID) {
                state.activeUserCases[activeCase.activeCaseIndex].UnreadMessages = data.UnreadMessages;
                state.activeUserCases[activeCase.activeCaseIndex].queue = data.queue;

                sameCase = true;
            }
        }

        if (!sameCase) {
            state.activeUserCases.push(data);
        }
        for (const [index, el] of state.activeUserCases.entries()) {
            el.activeCaseIndex = index;
        }
    },

    SET_LIST_SELECT(state, data) {
        state.showSelect = data;
    },
    SET_SEARCHTYPE(state, data) {
        state.searchType = data;
    },
    TRIGGER_NORMAL_ALERT(state, data) {
        state.normalAlertTrigger = data;
    },
    SET_CALL_NOW_TOGGLE(state, data) {
        state.callbackCallNow = data;
    },
    SET_COMMUNICTATION_TOGGLE(state, data) {
        state.toggleCommunication = data;
    },
    SET_CLIENT_ON_CARD(state, data) {
        state.clientNowOnCard = data;
    },
    SET_SIDEBAR_VIEW(state, data) {
        state.sideBarView = data;
    },
    SET_CASE_SORTING(state, data) {
        localStorage.setItem('__casesFilter', JSON.stringify(data));
        state.caseSorting = data;
    },
    SET_PAGINATION_SORTING(state, data) {
        localStorage.setItem('__casesPagination', JSON.stringify(data));
        state.caseSortingPagination = data;
    },
    SET_CASES_LIST(state, data) {
        state.casesList = data;
    },
    SET_CASES_DATA(state, data) {
        state.cases = data.cases;
        state.totalCases = data.totalCases;
    },

    SET_TEMPLATE_TEXT(state, data) {
        state.temporaryTemplate = data;
    },
    SET_COMMENT_CONTENT(state, data) {
        state.currentCommentContent = data;
    },
    SET_COMMENT_SUBJECT(state, data) {
        state.currentCommentSubject = data;
    },
    TOGGLE_ANSWERED_CASE(state, data) {
        state.answeredCase = data;
    },
    SET_MOVE_CASE(state, data) {
        state.moveCase = data;
    },

    SET_COMMUNICATION_CREATOR_DATA(state, data) {
        state.CommunicationCreatorData = data;
    },
    SET_SYSTEM_EMAILS(state, data) {
        state.systemEmails = data;
    },
    SET_SYSTEM_SMS_NUMBERS(state, data) {
        state.systemSmsNumbers = data;
    },
    SET_COMMENT_VIEW(state, data) {
        state.commentView = data;
    },

    SET_SHOW_FILTERS(state, data) {
        state.showFilters = data;
        localStorage.setItem('filtersState', data);
    },
    SET_SHOW_FILTERS_STATE(state, data) {
        state.showFiltersState = data;
        localStorage.setItem('showFiltersState', data);
    },

    SET_GO_TO_CONTENT(state, data) {
        state.goToContent = data;
    },
    SET_SHOW_ATTACH_CHECKBOXES(state, data) {
        state.showAttachCheckboxes = data;
    },
    SET_SEARCH_TYPE(state, data) {
        state.searchType = data;
        localStorage.setItem('searchType', data);
    },
    SET_SELECTED_SIGNATURE(state, data) {
        state.selectedCaseSignature = data;
    },

    SET_HEADER_LOCK(state, data) {
        state.headerLock = data;
    },

    SET_ALL_COMMENTS_IN_CASE_CHECKED(state, data) {
        state.allCommentsInCaseChecked = data;
    },

    SET_COUNTDOWN_INTERVAL(state, data) {
        state.countdownInterval = data;
    },

    SET_CASE_TO_LOAD(state, data) {
        state.caseToLoad = data;
    },

    CLEAR_COUNTDOWN_INTERVAL(state) {
        clearInterval(state.countdownInterval);
    },

    SET_SELECTED_CASE_PUSH_COMMENTS(state, { comments, hasMoreComments }) {
        // Copy the existing comments to a new array
        const updatedComments = [...state.selectedCase.comments];

        // Push new comments to the updatedComments array
        updatedComments.push(...comments);

        // Update selectedCase.comments with the updatedComments array
        state.selectedCase.comments = updatedComments;
        state.selectedCase.hasMoreComments = hasMoreComments;
    },

    SET_SELECTED_CASE_UNSHIFT_COMMENTS(state, { comments, hasMoreComments }) {
        // Copy the existing comments to a new array
        const updatedComments = [...state.selectedCase.comments];

        // Push new comments to the updatedComments array
        updatedComments.unshift(...comments);

        // Update selectedCase.comments with the updatedComments array
        state.selectedCase.comments = updatedComments;
        state.selectedCase.hasMoreComments = hasMoreComments;
    },

    SET_CASE_TIMELINE(state, data) {
        state.caseTimeline = data;
    },

    SET_ANIMATION_GRID_QUEUE(state, data) {
        if (state.animationGridQueue.length === 0) {
            state.animationGridQueue.push(data);
        }

        if (state.animationGridQueue.length === 1) {
            state.animationGridQueue = [data];
        }
    },

    REMOVE_ANIMATION_GRID_FIRST_ITEM(state) {
        state.animationGridQueue.shift();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
